import { useQuery } from '@tanstack/vue-query';
import { courseService } from '~/utils/api/courseService';
import type { KeepWatchingData, KeepWatchingRaw } from '~/utils/types';

export function useCourseOverview() {
  const { data: userCourses } = useUserCourses();
  const isWatchAgain = computed(() => {
    if (!userCourses.value) return false;

    const uncompleatedCourses = userCourses.value?.filter((course) =>
      course.lessons?.some((lesson) =>
        lesson.topics?.some((topic) => !topic.progress?.is_complete),
      ),
    );

    return uncompleatedCourses.length === 0;
  });

  const { data: keepWatchingApi, isLoading: isKeepWatchigLoading } = useQuery({
    queryKey: ['keep-watching'],
    queryFn: courseService.getKeepWatching,
  });

  const keepWatchingData = computed<KeepWatchingData[]>(() => {
    if (!keepWatchingApi.value) return [];
    if (!userCourses.value) return [];

    return parseData(keepWatchingApi.value.filter((watch) => watch.lesson_id));
  });

  const { data: watchAgainApi } = useQuery({
    queryKey: ['watch-again'],
    queryFn: () => {
      if (!isWatchAgain.value) {
        return Promise.resolve([]);
      }

      return courseService.getWatchAgain();
    },
  });

  const watchAgainData = computed<KeepWatchingData[]>(() => {
    if (!watchAgainApi.value) return [];
    if (!userCourses.value) return [];

    return parseData(watchAgainApi.value);
  });

  const startWatching = computed<KeepWatchingData | null>(() => {
    if (!userCourses.value) return null;
    if (!keepWatchingApi.value) return null;
    if (keepWatchingData.value.length) return null;

    const course = userCourses.value.find(
      (course) => course.lessons && course.lessons[0],
    );

    if (!course || !course.lessons) return null;

    const lesson = course.lessons[0];

    return {
      title: lesson.title,
      description:
        lesson.description ||
        'Dowiedz się więcej na temat tego działu i rozwiń swoje umiejętności.',
      id: lesson.id,
      progres: 0,
      stages: lesson.topics?.length,
      stage: 1,
      path: `/courses/${course.slug}/lessons/${lesson.slug}`,
      updated_at: null,
      img:
        course?.thumbnail_url ||
        'https://www.lm.pl/media/news_foto/136445-najpopularniejsze-kursy-online-jak-wybrac_1200.jpg',
    };
  });

  function parseData(data: KeepWatchingRaw[]) {
    const topics = data?.map((keepWatching) => {
      try {
        if (!keepWatching.course_id) {
          return {
            id: null,
          };
        }

        const { topic, lesson, course } = getTopicByIds({
          topic_id: keepWatching.topic_id,
          lesson_id: keepWatching.lesson_id,
          course_id: keepWatching.course_id,
        });

        return {
          title: topic.title,
          description:
            topic.description ||
            'Dowiedz się więcej na temat tego kursu i rozwiń swoje umiejętności.',
          id: topic.id,
          progres: topic.progress?.progress_percent,
          stages: lesson.topics?.length,
          stage: lesson.topics?.filter((topic) => topic.progress?.is_complete)
            ?.length,
          path: `/courses/${course.slug}/lessons/${lesson.slug}/topics/${topic.slug}`,
          updated_at: keepWatching.updated_at,
          img:
            course?.thumbnail_url ||
            'https://www.lm.pl/media/news_foto/136445-najpopularniejsze-kursy-online-jak-wybrac_1200.jpg',
        };
      } catch {
        return {
          id: null,
        };
      }
    });

    const filteredTopics = topics.filter((topic) => topic.id !== null);

    return filteredTopics;
  }

  function getTopicByIds({
    topic_id,
    lesson_id,
    course_id,
  }: {
    topic_id: number;
    lesson_id: number;
    course_id: number;
  }) {
    if (!userCourses.value) {
      throw new Error('Courses not loaded');
    }

    const course = userCourses.value.find((course) => course.id === course_id);

    if (!course || !course.lessons) {
      throw new Error(`Kurs z id ${course_id} nie został znaleziony`);
    }

    const lesson = course.lessons.find((lesson) => lesson.id === lesson_id);

    if (!lesson || !lesson.topics) {
      throw new Error(
        `Lekcja z id ${lesson_id} nie została znaleziona w kursie z id ${course_id}`,
      );
    }

    const topic = lesson.topics.find((topic) => topic.id === topic_id);

    if (!topic) {
      throw new Error(
        `Temat z id ${topic_id} nie został znaleziony w lekcji z id ${lesson_id} w kursie z id ${course_id}`,
      );
    }

    return { topic, lesson, course };
  }

  return {
    keepWatchingData,
    watchAgainData,
    isKeepWatchigLoading,
    startWatching,
  };
}
